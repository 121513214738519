import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  p.green,
  span.green {
    color: var(--green);
  }
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Next', 'Node.js', 'WordPress'];
  const libraries = [
    'Redux',
    'Styled Components',
    'Jest',
    'React Testing Library',
    'JQuery',
    'Bootstrap',
    'Tailwind CSS',
    'Material UI',
  ];
  const tools = ['Git', 'Figma', 'Photoshop', 'Illustrator', 'VS Code', 'Postman'];
  const familiar = ['GraphQL', 'Three.js', 'React Three Fiber', 'Netlify', 'Vercel', 'AWS EC2'];
  const learning = ['Python', 'Php', 'Liquid'];
  const hobbies = ['Writing', 'Reading', 'Learning'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Rizwan and I enjoy creating innovative things that live on the
              internet. I am a passionate and results-oriented front-end software engineer with 8+
              years of experience building user-friendly and interactive web applications. Skilled
              in utilizing modern frameworks and libraries to deliver high-performing and responsive
              UIs.
            </p>
            <p>
              Proven ability to collaborate effectively with designers and back-end engineers to
              bring complex projects to life. Eager to leverage strong problem-solving skills and a
              keen eye for detail to contribute to a team-oriented environment.
            </p>

            <p>
              Here are a <span className="">few technologies</span> I’ve been working with recently:
            </p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>

          <br />

          <p className="green">Libraries : </p>
          <ul className="skills-list">
            {libraries && libraries.map((lib, i) => <li key={i}>{lib}</li>)}
          </ul>

          <br />

          <p className="green">Tools : </p>
          <ul className="skills-list">
            {tools && tools.map((tool, i) => <li key={i}>{tool}</li>)}
          </ul>

          <br />

          <p className="green">Familiar with : </p>
          <ul className="skills-list">
            {familiar && familiar.map((fam, i) => <li key={i}>{fam}</li>)}
          </ul>

          <br />

          <p className="green">Learning : </p>
          <ul className="skills-list">
            {learning && learning.map((learn, i) => <li key={i}>{learn}</li>)}
          </ul>

          <br />

          <p className="green">Hobbies : </p>
          <ul className="skills-list">
            {hobbies && hobbies.map((hobby, i) => <li key={i}>{hobby}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me-r.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
