import React from 'react';

const IconCodepen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 24 24"
    style={{ enableBackground: 'new 0 0 512 512' }}
    xmlSpace="preserve">
    <g>
      <path
        d="M24 7.598c-.044-.264-.08-.47-.36-.641C12.191.166 12.353-.34 11.61.109 2.933 5.503.518 6.706.171 7.126-.144 7.449 0 7.944 0 15.424c-.021.851 7.743 5.462 11.519 8.404.333.237.752.199 1.003-.029C23.746 15.843 24.019 16.163 24 15.424c0 0-.012-7.927 0-7.826zm-1.5 6.491-3.876-2.359L22.5 9.033zm-5.277-3.212L12.75 8.155v-6.07l9.126 5.555zM12 14.51l-3.876-2.697L12 9.454l3.876 2.359zm-.75-12.426v6.074a537.339 537.339 0 0 1-4.451 2.734L2.124 7.64zm-5.857 9.658c-1.874 1.127-3.098 1.843-3.893 2.32V9.033zm1.33.924 4.527 3.149v5.999l-9.126-6.349zm6.027 9.149v-5.999l4.527-3.149 4.599 2.799z"
        opacity={1}
      />
    </g>
  </svg>
);

export default IconCodepen;
